<template>
  <div class="p-5">
    <h1 class="pt-4 pb-3">{{ $t('services.title') }}</h1>
    <h5 class="pb-2"> {{$t('services.des')}}</h5>
    <p v-for="elem in list" :key="elem">
      <b-icon-check-circle></b-icon-check-circle>
      {{ $t('services.' + elem) }}
    </p>
  </div>
</template>

<script>
import {BIconCheckCircle} from 'bootstrap-vue'

export default {
  components: {
    BIconCheckCircle,
  },
  data() {
    return {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    }
  }
}
</script>

<style scoped>
div {
  background-color: #865645;
  text-align: left;
  color: white;
}

h1 {
  text-align: center;
}

p {
  font-size: 18px;
}
</style>