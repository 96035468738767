<template>
  <div id="app">
    <img class="image" v-for="(link, i) in images" :src="link" :key="i" @click="index = i-1">
    <vue-gallery-slideshow :images="Object.values(images)" :index="index" @close="index = null"></vue-gallery-slideshow>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import {getDownloadURL, getStorage, ref} from "firebase/storage";

export default {
  components: {
    VueGallerySlideshow
  },
  async mounted() {
    const firebaseApp = firebaseApp;
    await Object.keys(this.images).forEach((key) =>
        getDownloadURL(ref(getStorage(firebaseApp), `cat/${key}.jpg`)).then(
            (url) => {
              this.images[key] = url;
            }
        )
    );
  },
  data() {
    return {
      images: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: ""
      },
      index: null
    }
  },
}
</script>

<style scoped>
img {
  width: 100px;
  cursor: pointer;
  padding: 2px;
}
</style>