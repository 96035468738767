<template>
  <b-card
      :title="$t('room.' + name + '.name')"
      class="m-2"
      :img-src=url
      img-alt="Image"
      img-top
      tag="article"
      style="width: 330px; display: inline-block;"
  >
    <b-card-text>
      {{ $t('room.' + name + '.des') }}
      &nbsp;
      <b-icon-star-fill/>
      &nbsp;
      {{ $t('room.' + name + '.type') }}
    </b-card-text>
  </b-card>
</template>

<script>
import {BIconStarFill} from 'bootstrap-vue'

export default {
  props: ["name", "des", "url"],
  components: {BIconStarFill}
}
</script>
