<template>
  <div>
    <Card v-for="elem in keySet" :key="elem" :name="elem" :url="list[elem]"></Card>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Card from "./Card.vue"

export default {
  components: {Card},
  async mounted() {
    const firebaseApp = firebaseApp;
    await Object.keys(this.list).forEach((key) =>
        getDownloadURL(ref(getStorage(firebaseApp), `room/${key}.jpg`)).then(
            (url) => {
              this.list[key] = url;
            }
        )
    );
    this.keySet = Object.keys(this.list)
  },
  data() {
    return {
      list: {
        double: "",
        twin: "",
        single: "",
        suite: "",
        triple: "",
      },
      keySet: []
    }
  }
}
</script>