<template>
  <div class="p-5">
    <h1 class="pt-2 pb-3">{{$t('location.title')}}</h1>
    <h5 class="pb-2">
      {{$t('location.des')}}
    </h5>
    <p v-for="elem in list" :key="elem">
      <b-icon-geo-fill/>
      {{ $t('location.' + elem) }}
    </p>
  </div>
</template>

<script>
import {BIconGeoFill} from 'bootstrap-vue'

export default {
  components: {
    BIconGeoFill,
  },
  data() {
    return {
      list: [1, 2, 3, 4, 5]
    }
  }
}
</script>

<style scoped>
div {
  background-color: #d9c9bb;
  text-align: left;
}

h1 {
  text-align: center;
}

p {
  font-size: 18px;
}
</style>