<template>
  <div id="app">
    <div class="nav">
      <b-navbar class="fixed-top" toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#top">
          <b-img src="@/assets/logo1.png" width="90px" class="logo"/>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#book">{{ $t('nav.book') }}</b-nav-item>
            <b-nav-item href="#location">{{ $t('nav.location') }}</b-nav-item>
            <b-nav-item href="#room">{{ $t('nav.room') }}</b-nav-item>
            <b-nav-item href="#service">{{ $t('nav.services') }}</b-nav-item>
            <b-nav-item href="#contact">{{ $t('nav.contact') }}</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">

            <b-nav-item @click.prevent="switchLang('en')">EN</b-nav-item>
            <b-nav-item @click.prevent="switchLang('cn')">中文</b-nav-item>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <Carousel/>
    <div id="book" class="space"></div>
    <Book/>
    <Location id="location"/>
    <Room id="room"/>
    <Services id="service"/>
    <Gallery class="p-3"/>
    <Contact id="contact"/>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue"
import Book from "@/components/Book.vue"
import Location from "@/components/Location.vue"
import Room from "@/components/Room.vue"
import Services from "@/components/Services.vue"
import Contact from "@/components/Contact.vue"
import Gallery from "@/components/Gallery";

export default {
  name: 'App',
  components: {
    Gallery,
    Carousel,
    Book,
    Location,
    Room,
    Services,
    Contact
  },
  methods: {
    switchLang(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$router.push({
          params: {lang: locale},
        });
      }
    }
  }
}
</script>

<style>
.space {
  position: absolute;
  top: 20vw;
}

.nav {
  margin-bottom: 3vw;
}

@media screen and (max-width: 500px) {
  .nav {
    margin-bottom: 16vw;
  }
}
</style>
