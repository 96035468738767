<template>
  <div>
    <b-carousel
        id="carousel-fade"
        style="text-shadow: 0 0 2px 0"
        fade
        indicators
        :interval="5000"
        img-width="1024"
        img-height="480"
    >
      <b-carousel-slide
          :img-src=list[1]
      ></b-carousel-slide>
      <b-carousel-slide
          :img-src="list[2]"
      ></b-carousel-slide>
      <b-carousel-slide
          :img-src="list[3]"
      ></b-carousel-slide>
      <b-carousel-slide
          :img-src="list[4]"
      ></b-carousel-slide>
      <b-carousel-slide
          :img-src="list[5]"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  async mounted() {
    const firebaseApp = firebaseApp;
    await Object.keys(this.list).forEach((key) =>
        getDownloadURL(ref(getStorage(firebaseApp), `carousel/${key}.jpg`)).then(
            (url) => {
              this.list[key] = url;
            }
        )
    );
  },
  data() {
    return {
      list: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
      },
    }
  }
}
</script>

<style scoped>
::v-deep h3{
  font-size: 5vw;
}
</style>