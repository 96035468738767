import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBcbA5jWQboCpYuyqO4-6FYzPJJCdnWKp4",
    authDomain: "shan-boutique-hotel.firebaseapp.com",
    projectId: "shan-boutique-hotel",
    storageBucket: "shan-boutique-hotel.appspot.com",
    messagingSenderId: "185350383495",
    appId: "1:185350383495:web:ca0bfa18dfa1387d455522"
};

const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase
export default firebaseApp;