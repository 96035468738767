<template>
  <div>
    <a :href="links.agoda"><img :src="images.agoda" alt="agoda logo" height="65px" class="logo"></a>
    <a :href="links.booking"><img :src="images.booking" alt="booking.com logo" height="85px" class="logo"></a>
    <a :href="links.traveloka"><img :src="images.traveloka" alt="traveloka logo" height="50px" class="logo"></a>
    <a :href="links.expedia"><img :src="images.expedia" alt="expedia logo" height="60px" class="logo ex"></a>
  </div>
</template>

<script>
import {getStorage, ref, getDownloadURL} from "firebase/storage";

export default {
  data() {
    return {
      images: {
        agoda: "",
        booking: "",
        traveloka: "",
        expedia: "",
      },
      links: {
        agoda: "https://www.agoda.com/cm-apartment/hotel/chiang-mai-th.html?cid=1844104",
        booking: "",
        traveloka: "https://www.traveloka.com/en-th/hotel/thailand/cm-apartment-4000000447517",
        expedia: "https://www.expedia.com/Chiang-Mai-Hotels-CM-Apartment.h32458990.Hotel-Information"
      }
    };
  },
  mounted() {
    const firebaseApp = firebaseApp;
    Object.keys(this.images).forEach((key) =>
        getDownloadURL(ref(getStorage(firebaseApp), `booking/${key}.png`)).then(
            (url) => {
              this.images[key] = url;
              // console.log(url)
            }
        )
    );
  },
};
</script>

<style scoped>
.logo {
  margin: 15px 35px 15px 35px;
  padding: 3px;
}
@media screen and (max-width: 500px) {
  .logo {
    display: block;
    margin: auto;
    padding: 10px;
    margin-top: 20px;
    /*border-bottom: #eeeeee 2px solid;*/
  }
}
</style>