import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "../i18n";
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: `/${i18n.locale}`,
    },
    {
        path: "/:lang",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [
            {
                path: "/",
                name: "Home",
                component: Home,
            },
            { path: '*', component: Home }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
    // use the language from the routing param or default language
    let language = to.params.lang;
    if (!language) {
        language = "en";
    }
    // set the current language for i18n
    i18n.locale = language;
    // go to top of the new page
    // window.scrollTo(0, 0);
    next();
});

export default router
